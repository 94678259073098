export const metaConstants = {
  GET_META_DEPARTMENTS_SUCCESS: 'GET_META_DEPARTMENTS_SUCCESS',
  GET_META_CATEGORIES_SUCCESS: 'GET_META_CATEGORIES_SUCCESS',
  GET_META_TAGS_SUCCESS: 'GET_META_TAGS_SUCCESS',
  GET_META_MEMBERS_SUCCESS: 'GET_META_MEMBERS_SUCCESS',
  GET_META_SPECIAL_CATEGORIES_SUCCESS: 'GET_META_SPECIAL_CATEGORIES_SUCCESS',
  GET_META_POSTS_STATUSES: 'GET_META_POSTS_STATUSES',
  GET_META_POSTS_KINDS: 'GET_META_POSTS_KINDS',
  GET_META_POSTS_WATERMARK: 'GET_META_POSTS_WATERMARK'
};
