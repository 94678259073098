import React from 'react';
import classNames from 'classnames';
import { TimePicker } from 'antd';
import moment from "moment";

export const renderTimeField = ({
  input,
  showMsg,
  change,
  meta: { touched, error }
}) => {
  let wraperClass = classNames(
    'input-group',
    {
      'has-errors':  touched && error
    }
  );

  return(
    <div className={wraperClass}>
      <TimePicker
          getPopupContainer={ele => ele.parentNode}
          onChange={(date, timeString) => {change(input.name, timeString);}}
          value={moment(input.value || '00:00', "HH:mm")}
          showTime
          format='HH:mm'
          className="mb-1 form-control time-invalid" />
      {showMsg && touched && (error && <div className="invalid-feedback">{error}</div>)}
    </div>
  );
};
