import axios from "axios";
import map from 'lodash/map';
import objectToFormData from "object-to-formdata";

function getFormWithFileData(params) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  let data = objectToFormData(params);
  if(params.logo) {
    // data.set('logo', params.logo);
    data.set('logo_image', params.logo);
  } else {
    data.delete('logo_image');
  }
  // it might pass large data
  data.delete('logo');
  data.delete('logo_url');

  return [data, config];
}

export const sectionService = {
  getSection,
  getSections,
  createSection,
  updateSection,
  deleteSection,
  removeLogo,
  addSectionDepartments,
  removeSectionDepartment,
  getSectionDepartments,
  updateSectionDepartmentsOrder,
  updateSectionOrder
};

function getSection(id, options) {
  return axios.get("/v1/sections/" + id,
    {params: { ...options }});
}

function getSections(options) {
  return axios.get("/v1/sections", {params: { ...options }});
}

function createSection(sectionParams) {
  const formSection = getFormWithFileData(sectionParams);
  return axios.post('/v1/sections', formSection[0], formSection[1]);
}

function updateSection(sectionParams) {
  const formSection = getFormWithFileData(sectionParams);
  return axios.put('/v1/sections/' + sectionParams.id,
    formSection[0], formSection[1]);
}

function deleteSection(id) {
  return axios.delete('/v1/sections/' + id);
}

function removeLogo(id) {
  return axios.delete('/v1/sections/' + id +'/remove_avatar');
}

function addSectionDepartments(sectionParams) {
  const departmentIds = map(sectionParams.departments, (cat) => (cat.id));
  return axios.post('/v1/sections/' + sectionParams.id + '/add_departments', {department_ids: departmentIds});
}

function removeSectionDepartment(sectionParams) {
  const departmentId = sectionParams.department.id;
  return axios.delete('/v1/sections/' + sectionParams.id + '/remove_department?department_id=' + departmentId);
}

function getSectionDepartments(id, options) {
  return axios.get("/v1/sections/" + id + '/departments',
    {params: { ...options }});
}

function updateSectionDepartmentsOrder(id, departmentsParams) {
  const departmentsIds = map(departmentsParams, (department) => department.id);
  return axios.put("/v1/sections/" + id + "/departments_update_order", {ids: departmentsIds});
}

function updateSectionOrder(sectionParams) {
  const depIds = map(sectionParams, (dep) => dep.id);
  return axios.put("/v1/sections/update_order", {ids: depIds});
}
