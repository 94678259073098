import axios from "axios";
import objectToFormData from 'object-to-formdata';
function getFormWithFeatureData(params) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  let data = objectToFormData({...params, content: null, feature_image: null});
  if(params.feature_image && params.feature_image.file) {
    data.set('feature_image', params.feature_image.file, 'feature.jpg');
  } else {
    data.delete('feature_image');
  }
  // if(params.feature_image && params.feature_image.remove === true) {
  //   data.set('remove_feature_image', true);
  //   data.delete('feature_image');
  // }

  data.set('content', JSON.stringify(params['content']));
  // it might pass large data
  // data.delete('logo_url');

  return [data, config];
}
export const postService = {
  getPost,
  getPosts,
  createPost,
  updatePost,
  deletePost,
  changePostAuthor,
  updatePostVIewCount,
  getPostHistory,
  sendPostTgNotification
};

function getPost(id, options) {
  return axios.get("/v1/posts/" + id,
    {params: { ...options }});
}

function getPosts(options) {
  return axios.get("/v1/posts", {params: { ...options }});
}

function createPost(postParams) {
  const formPost = getFormWithFeatureData(postParams);
  return axios.post('/v1/posts', formPost[0], formPost[1]);
}

function updatePost(postParams) {
  const formPost = getFormWithFeatureData(postParams);
  return axios.put('/v1/posts/' + postParams.id, formPost[0], formPost[1]);
}

function updatePostVIewCount(postParams) {
  return axios.put('/v1/posts/' + postParams.id + '/update_view_count', postParams);
}

function changePostAuthor(postId, authorId) {
  return axios.put('/v1/posts/' + postId + '/change_author', {author_id: authorId});
}

function deletePost(id) {
  return axios.delete('/v1/posts/' + id);
}

function getPostHistory(postId) {
  return axios.get('/v1/posts/' + postId + '/histories');
}

function sendPostTgNotification(id) {
  return axios.post('/v1/posts/' + id + '/send_tg_notification');
}