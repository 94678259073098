import { lazy } from 'react';
// Layout Types
import { DefaultLayout, AuthLayout } from "./../layouts";

import {permissionConstants} from "../constants";
// Route Views
const Dashboard = lazy(() => import('./../screens/Dashboard'));

const Categories = lazy(() => import('./../screens/Categories'));
const Schedules = lazy(() => import('./../screens/Schedules'));
const Media = lazy(() => import("./../screens/Media"));
const MemberProfile = lazy(() => import('./../components/members/MemberProfile'));
const MembersScreen = lazy(() => import('./../screens/MembersScreen'));
const StructuresScreen = lazy(() => import('./../screens/StructuresScreen'));
const PostsScreen = lazy(() => import('./../screens/PostsScreen'));
const RadiosScreen = lazy(() => import('./../screens/RadiosScreen'));
const TvScreen = lazy(() => import('./../screens/TvScreen'));
const DepartmentsScreen = lazy(() => import('./../screens/DepartmentsScreen'));
const SectionsScreen = lazy(() => import('./../screens/SectionsScreen'));
const ReportsScreen = lazy(() => import('./../screens/ReportsScreen'));
const BannersScreen = lazy(() => import('./../screens/BannersScreen'));
const SettingsScreen = lazy(() => import('./../screens/SettingsScreens'));
const HomePage = lazy(() => import('./../screens/HomePage'));
const SignInPage = lazy(() => import('./../screens/SignInPage'));
const ForgotPasswordPage = lazy(() => import('./../screens/ForgotPasswordPage'));
const ChangePasswordPage = lazy(() => import('./../screens/ChangePasswordPage'));
export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: Dashboard,
    allowRoles: permissionConstants.normalPermission,
  },
  {
    path: "/posts",
    layout: DefaultLayout,
    component: PostsScreen,
    allowRoles: permissionConstants.normalPermission,
  },
  {
    path: "/media",
    exact: true,
    layout: DefaultLayout,
    component: Media,
    allowRoles: permissionConstants.normalPermission,
  },
  {
    path: "/my-profile",
    exact: true,
    layout: DefaultLayout,
    component: MemberProfile,
    allowRoles: permissionConstants.normalPermission,
  },
  {
    path: "/radios",
    exact: true,
    layout: DefaultLayout,
    component: RadiosScreen,
    allowRoles: permissionConstants.adminPermission,
  },
  {
    path: "/televisions",
    exact: true,
    layout: DefaultLayout,
    component: TvScreen,
    allowRoles: permissionConstants.adminPermission,
  },
  {
    path: "/schedules",
    exact: true,
    layout: DefaultLayout,
    component: Schedules,
    allowRoles: permissionConstants.adminPermission,
  },
  {
    path: "/categories",
    exact: true,
    layout: DefaultLayout,
    component: Categories,
    allowRoles: permissionConstants.adminPermission,
  },
  {
    path: "/departments",
    layout: DefaultLayout,
    component: DepartmentsScreen,
    allowRoles: permissionConstants.adminPermission,
  },
  {
    path: "/sections",
    layout: DefaultLayout,
    component: SectionsScreen,
    allowRoles: permissionConstants.adminPermission,
  },
  {
    path: "/reports",
    layout: DefaultLayout,
    component: ReportsScreen,
    allowRoles: permissionConstants.adminPermission,
  },
  {
    path: "/members",
    layout: DefaultLayout,
    component: MembersScreen,
    allowRoles: permissionConstants.adminPermission,
  },
  {
    path: "/structures",
    layout: DefaultLayout,
    component: StructuresScreen,
    allowRoles: permissionConstants.adminPermission,
  },
  {
    path: "/banners",
    layout: DefaultLayout,
    component: BannersScreen,
    allowRoles: permissionConstants.adminPermission,
  },
  {
    path: "/settings",
    layout: DefaultLayout,
    component: SettingsScreen,
    allowRoles: permissionConstants.adminPermission,
  },

  {
    path: "/",
    plublic: true,
    exact: true,
    layout: AuthLayout,
    component: HomePage,
    allowRoles: permissionConstants.normalPermission,
  },
  {
    path: "/signin",
    plublic: true,
    exact: true,
    layout: AuthLayout,
    component: SignInPage,
    allowRoles: permissionConstants.normalPermission,
  },
  {
    path: "/forgot-password",
    plublic: true,
    layout: AuthLayout,
    component: ForgotPasswordPage,
    allowRoles: permissionConstants.normalPermission,
  },
  {
    path: "/change-password/:token",
    plublic: true,
    layout: AuthLayout,
    component: ChangePasswordPage,
    allowRoles: permissionConstants.normalPermission,
  }
];
