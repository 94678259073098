import axios from "axios";

export const reportService = {
  generateReport,
  manualGenerateReport,
};

function generateReport(options) {
  return axios.get("/v1/reports", { params: { ...options } });
}

function manualGenerateReport(params) {
  return axios.post("/v1/reports/manual_report", params);
}
