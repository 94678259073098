import axios from "axios";
import map from 'lodash/map';
import each from 'lodash/each';
import objectToFormData from "object-to-formdata";

function getFormWithFileData(params) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  let data = objectToFormData(params);
  if(params.logo) {
    // data.set('logo', params.logo);
    data.set('logo_image', params.logo);
  } else {
    data.delete('logo_image');
  }
  // it might pass large data
  data.delete('logo');
  data.delete('logo_url');

  return [data, config];
}
export const structureService = {
  getStructure,
  getStructures,
  createStructure,
  updateStructure,
  deleteStructure,
  updateStructuresTree,
  fetchAvailableUsers,
  addStructtureUsers,
  removeStructureUser,
  removeLogo,
  updateStructureUsersOrder
};

function getStructure(id, options) {
  return axios.get("/v1/structures/" + id,
    {params: { ...options }});
}

function getStructures(options) {
  return axios.get("/v1/structures", {params: { ...options }});
}

function createStructure(structureParams) {
  const formStructure = getFormWithFileData(structureParams);
  return axios.post('/v1/structures', formStructure[0], formStructure[1]);
}

function updateStructure(structureParams) {
  const formStructure = getFormWithFileData(structureParams);
  return axios.put('/v1/structures/' + structureParams.id, formStructure[0], formStructure[1]);
}

function deleteStructure(id) {
  return axios.delete('/v1/structures/' + id);
}

function updateStructuresTree(treeData) {
  let paramData = [];
  let order = 0;
  let loop = (data, parent_id) => {
    each(data, tree => {
      paramData.push({id: tree.id, parent_id: parent_id, order: order});
      order++;
      loop(tree.children, tree.id);
    });
  };
  loop(treeData, null);

  return axios.put('/v1/structures/update_structures_tree', {tree: paramData});
}

function fetchAvailableUsers(options) {
  return axios.get("/v1/structures/available_users", {params: { ...options }});
}

function addStructtureUsers(structureParams) {
  const userIds = map(structureParams.users, (user) => (user.id));
  return axios.post('/v1/structures/' + structureParams.id + '/add_users', {user_ids: userIds});
}

function removeStructureUser(structureParams) {
  const userId = structureParams.user.id;
  return axios.delete('/v1/structures/' + structureParams.id + '/remove_user?user_id=' + userId);
}

function removeLogo(id) {
  return axios.delete('/v1/structures/' + id +'/remove_structure_logo');
}

function updateStructureUsersOrder(id, usersParams) {
  const userIds = map(usersParams, (user) => user.id);
  return axios.put("/v1/structures/" + id + "/users_update_order", {ids: userIds});
}
