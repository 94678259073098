import axios from "axios";
import objectToFormData from "object-to-formdata";

function getFormWithFileData(params) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  let data = objectToFormData(params);
  if(params.logo) {
    data.set('logo', params.logo);
  } else {
    data.delete('logo');
  }
  // it might pass large data
  data.delete('logo_url');

  return [data, config];
}

export const scheduleService = {
  getSchedule,
  getSchedules,
  createSchedule,
  updateSchedule,
  deleteSchedule,
};

function getSchedule(id, options) {
  return axios.get("/v1/schedules/" + id,
    {params: { ...options }});
}

function getSchedules(options) {
  return axios.get("/v1/schedules", {params: { ...options }});
}

function createSchedule(scheduleParams) {
  const formSchedule = getFormWithFileData(scheduleParams);
  return axios.post('/v1/schedules', formSchedule[0], formSchedule[1]);
}

function updateSchedule(scheduleParams) {
  const formSchedule = getFormWithFileData(scheduleParams);
  return axios.put('/v1/schedules/' + scheduleParams.id, formSchedule[0], formSchedule[1]);
}

function deleteSchedule(id) {
  return axios.delete('/v1/schedules/' + id);
}
