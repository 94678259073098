import axios from "axios";
import map from 'lodash/map';
import objectToFormData from "object-to-formdata";

function getFormWithFileData(params) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  let data = objectToFormData(params);
  if(params.avatar) {
    // data.set('logo', params.logo);
    data.set('avatar_image', params.avatar);
  } else {
    data.delete('avatar_image');
  }
  // it might pass large data
  data.delete('avatar');
  data.delete('avatar_url');

  return [data, config];
}

export const stationService = {
  fetchStation,
  fetchStations,
  createStation,
  updateStation,
  deleteStation,
  removeAvatar,
  updateStationsOrder
};

function fetchStation(id, options) {
  return axios.get("/v1/stations/" + id,
    {params: { ...options }});
}

function fetchStations(options) {
  return axios.get("/v1/stations", {params: { ...options }});
}

function createStation(stationParams) {
  const formUser = getFormWithFileData(stationParams);

  return axios.post('/v1/stations',
                    formUser[0], formUser[1]);
}

function updateStation(stationParams) {
  const formUser = getFormWithFileData(stationParams);

  return axios.put('/v1/stations/' + stationParams.id,
                    formUser[0], formUser[1]);
}

function deleteStation(id) {
  return axios.delete('/v1/stations/' + id);
}

function removeAvatar(id) {
  return axios.delete('/v1/stations/' + id + '/remove_avatar');
}

function updateStationsOrder(stationsParams) {
  const stationIds = map(stationsParams, (station) => station.id);
  return axios.put('/v1/stations/update_order', {ids: stationIds});
}
